var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          staticClass: "mx-2",
          attrs: {
            dark: "",
            loading: _vm.loading,
            disabled: _vm.qtids.length == 0,
            color: "error"
          },
          on: { click: _vm.deleteQualifications }
        },
        [_vm._v(" Delete (" + _vm._s(_vm.qtids.length) + ") ")]
      ),
      _c("tutti-snackbar", { ref: "snackbar" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }