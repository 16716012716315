var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "my-10", attrs: { justify: "center" } },
    [
      _c("v-col", { staticClass: "text-right", attrs: { cols: "10" } }, [
        _c(
          "div",
          { staticStyle: { display: "flex", "justify-content": "flex-end" } },
          [
            _c("delete-qual-button", {
              attrs: { duct: _vm.duct, qtids: _vm.selectedQualTypeIds },
              on: {
                delete: function($event) {
                  return _vm.getQualificationTypeIds()
                }
              }
            }),
            _c("create-qual-button", {
              attrs: { duct: _vm.duct },
              on: {
                create: function($event) {
                  return _vm.getQualificationTypeIds()
                }
              }
            })
          ],
          1
        )
      ]),
      _c(
        "v-col",
        { attrs: { cols: "10" } },
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              dense: "",
              "show-expand": "",
              "show-select": "",
              "sort-desc": "",
              headers: _vm.qualHeaders,
              items: _vm.qualsList,
              "single-expand": false,
              expanded: _vm.expanded,
              "item-key": "name",
              loading: _vm.loading,
              "sort-by": "creationTime"
            },
            on: {
              "update:expanded": function($event) {
                _vm.expanded = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "item.qualificationId",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "text-truncate",
                        staticStyle: { "max-width": "100px" }
                      },
                      [_vm._v(" " + _vm._s(item.qualificationId) + " ")]
                    )
                  ]
                }
              },
              {
                key: "item.num_workers",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", loading: item.num_workers == -2 },
                        on: {
                          click: function($event) {
                            item.num_workers = -2
                            _vm.listWorkersWithQualificationType(
                              item.qualificationId
                            )
                          }
                        }
                      },
                      [
                        item.num_workers >= 0
                          ? _c("span", [
                              _vm._v(" " + _vm._s(item.num_workers) + " ")
                            ])
                          : _c("span", [_vm._v(" ... ")])
                      ]
                    )
                  ]
                }
              },
              {
                key: "top",
                fn: function() {
                  return [
                    _c(
                      "v-card-title",
                      [
                        _vm._v(" Qualifications "),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function($event) {
                                return _vm.getQualificationTypeIds()
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("mdi-refresh")])],
                          1
                        ),
                        _c("v-spacer"),
                        _c("v-text-field", {
                          attrs: {
                            "single-line": "",
                            "hide-details": "",
                            "append-icon": "mdi-magnify",
                            label: "Search"
                          },
                          model: {
                            value: _vm.search,
                            callback: function($$v) {
                              _vm.search = $$v
                            },
                            expression: "search"
                          }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "expanded-item",
                fn: function(ref) {
                  var headers = ref.headers
                  var item = ref.item
                  return [
                    _c("td", { attrs: { colspan: headers.length } }, [
                      _c(
                        "div",
                        { staticClass: "my-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row-reverse" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", color: "grey lighten-1" }
                                },
                                [_c("v-icon", [_vm._v("mdi-pencil")])],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", color: "grey lighten-1" }
                                },
                                [_c("v-icon", [_vm._v("mdi-account-edit")])],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" Description: "),
                          _c("b", [_vm._v(_vm._s(item.detail.Description))]),
                          _c("br"),
                          _vm._v(" Created at: "),
                          _c("b", [_vm._v(_vm._s(item.detail.CreationTime))]),
                          _c("br"),
                          _vm._v(" Automatically grant on submission: "),
                          _c("b", [_vm._v(_vm._s(item.detail.AutoGranted))]),
                          _c("br"),
                          _vm._v(" # of assigned workers: "),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                item.detail.workers
                                  ? item.detail.workers.length
                                  : "retrieving..."
                              )
                            )
                          ]),
                          _c("br"),
                          _vm._v(" Raw data: "),
                          _c("vue-json-pretty", {
                            staticStyle: { "font-size": "0.6em" },
                            attrs: { data: item.detail, deep: 1 }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                }
              }
            ]),
            model: {
              value: _vm.selectedQualTypes,
              callback: function($$v) {
                _vm.selectedQualTypes = $$v
              },
              expression: "selectedQualTypes"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }